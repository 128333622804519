import { graphql } from 'gatsby'
import * as React from 'react'

import FreelancerSurveyPage from '../components/pages/FreelancerSurveyPage'
import DefaultLayout from '../layouts'

const FreelancerSurvey = () => (
  <DefaultLayout
    language="en"
    title="The State of Freelancing in Israel 2020 - Freelancers"
    description="Freelancers and industry leaders give their thoughts on the current state of freelancing in Israel"
  >
    <FreelancerSurveyPage />
  </DefaultLayout>
)

export default FreelancerSurvey

export const pageQuery = graphql`
  query FreelancerSurveyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
